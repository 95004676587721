import React from "react";
import { twMerge } from "tailwind-merge";

function ThemedSuspense({ className }) {
  return (
    <div
      className={twMerge(
        ` w-full h-screen flex justify-center font-semibold items-center text-center p-6`,
        className
      )}
    >
      <h1>Loading...</h1>
    </div>
  );
}

export default ThemedSuspense;
