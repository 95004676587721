import React, { Suspense, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Main from "./Main";
import ThemedSuspense from "../components/LoadingState/ThemedSuspense";
import Page404 from "../pages/404";
import { AuthContext } from "../context/AuthContext";

const Layout = () => {
  const { routes, userListLoading, menuListLoading } = useContext(AuthContext);

  return (
    <div className={`h-screen overflow-x-hidden overflow-y-auto bg-gray-200`}>
      <div className="h-full flex flex-col overflow-auto w-full max-w-[1920px] mx-auto ">
        <div className="h-full flex overflow-x-hidden overflow-y-auto">
          <div className="shrink-0">
            <div className="h-full relative">
              <Sidebar />
            </div>
          </div>
          <div className="flex flex-col flex-1 w-full bg-bgContainer overflow-x-hidden">
            <div className="sticky top-0 z-30">
              <Header />
            </div>
            <Main>
              {userListLoading || menuListLoading ? (
                <ThemedSuspense className={"h-[85vh]"} />
              ) : (
                <Suspense fallback={<ThemedSuspense />}>
                  <Routes>
                    {routes?.map((route, i) => {
                      return route.component ? (
                        <Route
                          key={i}
                          path={`${route.path}`}
                          element={<route.component />}
                        />
                      ) : null;
                    })}
                    <Route path="*" element={<Page404 />} />
                  </Routes>
                </Suspense>
              )}
            </Main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
