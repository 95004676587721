// PopoverComponent.js
import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import TextBase from "../ResponsiveText/TextBase";

const PopoverComponent = ({
  anchorEl,
  onClose,
  open,
  anchorOrigin,
  transformOrigin,

  //   message,
  //   handleClick,
  menuItems,
  handleMenuItemClick,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      style={{ marginTop: "20px", cursor: "pointer" }}
    >
      <div className="min-w-50 max-h-40 flex py-2">
        <div
          className=""
          // handleMenuItemClick={handleClick}
        >
          {menuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              onClick={() => handleMenuItemClick(menuItem.title)}
            >
              <div className="flex">
                {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                <TextBase label={menuItem.title} />
                {/* <Typography>{menuItem.title}</Typography> */}
              </div>
            </MenuItem>
          ))}
        </div>
      </div>
    </Popover>
  );
};

export default PopoverComponent;
