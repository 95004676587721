import React from "react";
import { CrossWhiteIcon, RegistrationSuccessIcon } from "../icons";
import takeSelfieIcon from "../icons/takingPicture.svg";

import { Link } from "react-router-dom";

const SignupSuccess = ({ open, action, isFromResetPassword }) => {
  return (
    <>
      {open ? (
        <div className="absolute top-0 min-h-screen w-full bg-bgSuccess">
          {/* <div className="absolute right-8 top-3 sm:right-12 sm:top-5 md:right-16 md:top-7 lg:right-24 lg:top-10">
            <CrossWhiteIcon
              className="w-7 h-7 md:w-10 md:h-10 cursor-pointer"
              onClick={action}
            />
          </div> */}

          <div className="h-screen flex flex-col justify-center">
            <div className="flex flex-col py- justify-center items-center px-5">
              <RegistrationSuccessIcon />
              <label className="mt-[6%] text-white text-4xl font-bold">
                Success ...
              </label>
              <label className="text-white text-base font-normal mt-4 text-center">
                {isFromResetPassword
                  ? "Password reset successfully. Please log in to continue"
                  : "Registered successfully. Please log in to continue."}
              </label>
              <div className="text-base font-normal text-center">
                <p className="text-white">
                  Go to{" "}
                  <Link to={"/auth/login"}>
                    {" "}
                    <span className="text-bluePrimary text-2xl font-bold">
                      login
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SignupSuccess;
