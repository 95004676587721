import { create } from "zustand";

const initialValue = {
  phoneNumberLength: 10,
  countryCode: "in",
  countryDetails: { dialCode: "+91", countryName: "India" },
};

const getPhoneNumberLength = (format, dialCode) => {
  const contactLength = format.replace(/[\s-+()]/g, "").length;
  const dialCodeLength = dialCode.length;
  return contactLength - dialCodeLength;
};

const phoneInputStore = (set) => ({
  ...initialValue,
  setPhoneNumberLength: (format, dialCode) =>
    set((state) => ({
      phoneNumberLength: getPhoneNumberLength(format, dialCode),
    })),
  setCountryDetails: (details) =>
    set((state) => ({
      countryDetails: {
        dialCode: "+" + details?.dialCode,
        countryName: details?.name,
      },
      countryCode:
        details?.iso2 && details?.iso2 ? details?.iso2 : details?.countryCode,
    })),
  reset: () => set(() => ({ ...initialValue })),
});

export const usePhoneInputStore = create(phoneInputStore);
