import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const CustomTooltip = ({ children, title, placement }) => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#757575",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontWeight: 600,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      "&::before": {
        backgroundColor: theme.palette.common.white,
        border: "0.5px solid #fff",
        boxShadow: theme.shadows[1],
      },
    },
  }));
  return (
    <div>
      <LightTooltip title={title} placement={placement} arrow={true}>
        <div>{children}</div>
      </LightTooltip>
    </div>
  );
};

export default CustomTooltip;
