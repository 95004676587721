import React from "react";

function Main({ children }) {
  return (
    // <main className="h-full overflow-y-auto ">
    //   <div className="px-5 lg:mx-auto ">{children}</div>
    //   {/* {children} */}
    // </main>
    <>{children}</>
  );
}

export default Main;
