import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import Layout from '../../container/Layout';
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = () => {
    const {userId, session} = useContext(AuthContext)
  return session ? <Layout /> : <Navigate to="/" />;
}

export default ProtectedRoutes