import { lazy } from "react";

const FaceConfiguration = lazy(() => import("../pages/FaceConfiguration"));
// const Dashbord = lazy(() => import("../pages/Dashboard"));
const PlatformAdminDbRoutes = lazy(() => import("./PlatformAdminDbRoutes"));
const FeedBackDbRoutes = lazy(() => import("./FeedBackDbRoutes"));
const Reports = lazy(() => import("../pages/Reports/Reports"));
const UserDetailsRoutes = lazy(() => import("./UserDetailsRoutes"));
const Profile = lazy(() => import("../pages/ProfileManagement/Profile"));
const UsersList = lazy(() => import("../pages/UserManagement/UsersList"));
const RolesList = lazy(() => import("../pages/RoleManagement/RolesList"));

//Routes for partner management feature
const CameraRoutes = lazy(() => import("./CameraRoutes"));
const SiteRoutes = lazy(() => import("./SiteRoutes"));
const PartnerRoutes = lazy(() => import("./PartnerRoutes"));
const AddSite = lazy(() => import("../pages/SiteManagement/AddSite"));

const Settings = lazy(() => import("../pages/Settings"));
const Tools = lazy(() => import("../pages/Reset"));
const UploadAppHomePage = lazy(() => import("../pages/UploadAppHomePage"));

//Routes for Face identification feature
const FaceId = lazy(() => import("../pages/FaceId/FaceId"));
const FaceDetails = lazy(() => import("../pages/FaceId/FaceDetails"));
const SimilarFaces = lazy(() => import("../pages/FaceId/SimilarFaces"));
const ReadyToShare = lazy(() => import("../pages/FaceId/ReadyToShare"));
const ShowAllImages = lazy(() => import("../pages/FaceId/ShowAllImages"));
// const FaceSetting = lazy(() => import("../pages/FaceId/FaceSetting"));
const FeedBackDashBoard = lazy(() => import("../pages/FeedBackDashBoard"));

//This routes for Platform Admin
const platformAdmin = [
  // {
  //   path: "/partner-management/*",
  //   component: PartnerRoutes,
  // },
  // {
  //   path: "/site-management/*",
  //   component: SiteRoutes,
  // },
  // {
  //   path: "/camera-management/*",
  //   component: CameraRoutes,
  // },
  {
    path: "/Settings",
    component: Settings,
  },
  {
    path: "/tools/reset-data",
    component: Tools,
  },
  {
    path: "/add-site",
    component: AddSite,
  },
  {
    path: "/tools/document",
    component: UploadAppHomePage,
  },
  {
    path: "/tools/face-recognition-configuration",
    component: FaceConfiguration,
  },
  {
    path: "/home",
    component: PlatformAdminDbRoutes,
  },
  {
    path: "/reports",
    component: Reports,
  },
  {
    path: "/user-details",
    component: UserDetailsRoutes,
  },
  {
    path: "/feedback",
    component: FeedBackDbRoutes,
  },
];

//This routes for Enterprise Admin
const enterpricseAdmin = [
  {
    path: "/faceIdentification",
    component: FaceId,
  },
  {
    path: "/faceIdentification/:photoId",
    component: FaceDetails,
  },
  {
    path: "/faceIdentification/:photoId/:id",
    component: SimilarFaces,
  },
  {
    path: "/add-site",
    component: AddSite,
  },
  {
    path: "/ready-to-share",
    component: ReadyToShare,
  },
  {
    path: "/ready-to-share/all/:faceId",
    component: ShowAllImages,
  },
];

//This routes are Common routes
const common = [
  {
    path: "/user-management/users",
    component: UsersList,
  },

  {
    path: "/user-management/roles",
    component: RolesList,
  },
  {
    path: "/my-profile",
    component: Profile,
  },
];

//Face listing

export const getRoute = (role) => {
  switch (role) {
    case "EA3":
      return [...common, ...enterpricseAdmin];
    case "PA_Role":
      return [...common, ...platformAdmin];
  }
};
