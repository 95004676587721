import React from "react";
import { QliqleLogo } from "../../icons";

const LoginTemplate = ({
  IconClassname,
  leftPannelText,
  rightPanelIcon,
  rightPanelIconSvg,
  children,
  panelClassName,
}) => {
  return (
    <div className=" flex flex-col h-screen">
      {/* <label className="absolute top-6 left-10 text-4xl font-bold">Qliqle</label> */}
      {/* <div className="mt-8 ml-6 md:mt-10 md:ml-28 xl:mt-10 xl:ml-32 h-12 w-32">
      <QliqleLogo className="" />
      </div> */}
      {/* <QliqleLogo className="absolute top-3 left-3 md:top-6 md:left-8 lg:top-8 lg:left-12 w-32 h-12 md:w-40 md:h-16" /> */}
      <div className="relative flex flex-1 items-center mt- md:mt-0">
        <div className="w-full max-w-[80rem] mx-auto overflow-hidden">
          <div className="absolute top-7 pl-9">
            <QliqleLogo className="w-36"  />
          </div>
          <div className="flex flex-col w-full gap-4 justify-between items-center h-auto overflow-y-auto md:flex-row-reverse">
            <div
              className={`flex-col w-0 h-0 md:h-auto md:w-full selection:justify-center overflow-hidden`}
            >
              {rightPanelIcon ? (
                <img
                  aria-hidden="true"
                  className={`object-cover w-full`}
                  src={rightPanelIcon}
                  alt="Group of people"
                />
              ) : (
                <div className="w-full object-contain flex justify-center">
                  {rightPanelIconSvg}
                </div>
              )}
            </div>
            <main className="relative w-full transition-all duration-300 px-10 sm:px-20 md:px-0 md:w-1/2 py-28 md:pl-10 mx-auto">
              <>
                <div>{children}</div>
              </>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginTemplate;
