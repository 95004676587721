import { Form, Formik } from "formik";
import React, { useState } from "react";
import TextField from "../TextField";
import * as Yup from "yup";
import FilledButton from "../Button/FilledButton";
import { Link } from "react-router-dom";
import ValidationSchema from "../../common/yupValidation";
import { useMutation } from "react-query";
import { loginRequest } from "../../lib/onboarding-api";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import Alert from "@mui/material/Alert";

const SigninForm = () => {
  const { setSessionValue, setUserMailId } = useContext(AuthContext);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const { emailValidation, passwordValidation } = ValidationSchema();

  const { mutate: loginAPI, isLoading: isLoginLoading } =
    useMutation(loginRequest);

  const validationScheme = Yup.object().shape({
    emailId: emailValidation.required("Email is required"),
    password: passwordValidation,
  });

  const initialValues = {
    emailId: "",
    password: "",
  };

  const initialType = [
    { type: "text", placeholder: "Email", label: "Email" },
    { type: "password", placeholder: "Password", label: "Password" }, // Add the password field to the initialType array
  ];

  return (
    <div className="max-h-screen w-full md:max-w-[22.5rem]">
      <Formik
        initialValues={{
          emailId: "",
          password: "",
        }}
        validateOnMount
        validationSchema={validationScheme}
        onSubmit={(values) => {
          let payload = {
            email: values.emailId,
            password: values.password,
          };
          // console.log({ payload });
          loginAPI(
            { payload },
            {
              onSuccess: (response) => {
                // setUserId(response?.data?.user_id)
                setUserMailId(values.emailId);
                // setPassword(values.password);
                let sessionObj = {
                  accessToken: response?.data?.access_token,
                  refreshToken: response?.data?.refresh_token,
                };
                setSessionValue(sessionObj);
                // setAccessToken(response?.data?.access_token)
                // setRefreshToken(response?.data?.refresh_token)
                setSeverity("success");

                setMessage("Login successful");
              },
              onError: (error) => {
                console.warn(error);
                setSeverity("error");
                setMessage(error.response.data.message);
                setTimeout(() => {
                  setSeverity("");
                  setMessage("");
                }, 10000);
                // console.log(error.response.data.message);
              },
            }
          );
        }}
      >
        {({ errors, status, isValid }) => (
          <Form>
            <div className="mt-8">
              {Object.keys(initialValues).map((name, index) => (
                <div key={index} className="mt-4">
                  {initialType[index].type === "text" && (
                    <TextField
                      name={name}
                      label={initialType[index].label}
                      fieldClassName={"h-10  text-xs"}
                      inputClassName={"mt-4"}
                      error={errors[name]}
                      isMandatory={true}
                      isClearable={true}
                    />
                  )}
                  {initialType[index].type === "password" && (
                    <TextField
                      name={name}
                      type="password"
                      label={initialType[index].label}
                      fieldClassName={"h-10  text-xs"}
                      error={errors[name]}
                      isMandatory={true}
                      isClearable={true}
                    />
                  )}
                </div>
              ))}
              <div className="w-full text-end">
                <Link to="/auth/forget-password">
                  <span className="text-bluePrimary text-sm font-medium cursor-pointer">
                    Forget password
                  </span>
                </Link>
              </div>
              <div>
                <FilledButton
                  type="submit"
                  label={"Submit"}
                  disabled={!isValid || isLoginLoading}
                  buttonClassName={`mt-8`}
                />
              </div>
              <div className="mt-4">
                {message && <Alert severity={severity}>{message}</Alert>}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SigninForm;
