import { useField } from "formik";
import React, { useState } from "react";
import InputLabel from "../InputLabel";
import { CrossIcon } from "../../icons";
import IconButton from "@mui/material/IconButton";
import { InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const TextField = ({
  name,
  label,
  type,
  error,
  isMandatory,
  labelClassName,
  labelColor,
  isClearable,
  inputClassName,
  placeholder,
  fieldClassName,
  disabled,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(true);
  const [field, meta, helpers] = useField(name);
  const configTextField = {
    ...field,
    ...rest,
    // fullWidth: true,
    autoComplete: "off",
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRemove = () => {
    helpers.setValue("");
  };

  return (
    <div className={`flex flex-col ${inputClassName}`}>
      {label && (
        <InputLabel
          label={label}
          color={labelColor}
          labelClassName={labelClassName}
          isMandatory={isMandatory}
        />
      )}
      <div className={`relative `}>
        <input
          disabled={disabled}
          type={type === "password" && showPassword ? "password" : "text"}
          placeholder={placeholder ? placeholder : ""}
          className={`h-11 w-full pl-2 pr-10 rounded-lg border text-sm border-gray-300 disabled:bg-bgDisabled ${fieldClassName} ${
            configTextField.error ? "border-red-400" : ""
          }`}
          {...configTextField}
        />
        {type === "password" ? (
          <div className="absolute top-[22px] right-4">
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          </div>
        ) : isClearable ? (
          <div className="hover:bg-gray-400 hover:bg-opacity-10 absolute top-0.5 transition-all duration-300 right-1.5 cursor-pointer rounded-full p-2">
            <CrossIcon className="" onClick={handleRemove} />
          </div>
        ) : (
          ""
        )}
      </div>
      {configTextField.error && (
        <div className="text-red-400 text-xs">{error}</div>
      )}
    </div>
  );
};

export default TextField;
