import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import * as Icons from "../../icons";
import { ClickAwayListener } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import SidebarSkltn from "../Skeloton/SidebarSkeloton";

function Icon({ icon, ...props }) {
  const Icon = Icons[icon];
  if (Icon) return <Icon {...props} />;
}

const MobileSidebar = () => {
  const {
    sideMenuList,
    menuListLoading,
    openMobileSidebar,
    handleMobileSidebarOpen,
  } = useContext(AuthContext);

  const menuItems = [
    {
      name: "My Profile",
      icon: "SiteIcon",
      path: "/app/my-profile",
    },
    { name: "Logout", icon: "LogoutIcon", path: "" },
  ];

  const { logout } = useContext(AuthContext);

  const handleClickAway = () => {
    handleMobileSidebarOpen();
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="absolute h-screen z-50 ">
      <div
        className={`relative w-full h-full z-50 ${
          openMobileSidebar
            ? "bg-white overflow-auto shadow-md"
            : "bg-transparent"
        }`}
      >
        {openMobileSidebar && (
          <ClickAwayListener onClickAway={handleClickAway}>
            {menuListLoading ? (
              <div className="w-[17rem] overflow-hidden h-full">
                <SidebarSkltn />
              </div>
            ) : (
              <div className="grow flex flex-col overflow-y-auto h-full">
                <ul className="mt-6">
                  {sideMenuList?.map((route, index) => (
                    // {routes.map((route, index) => (
                    <div key={index}>
                      <NavLink to={route?.path !== "" && route.path}>
                        {({ isActive }) => (
                          <li
                            className={`py-2 px-4 min-w-max ${
                              route?.path !== ""
                                ? "cursor-pointer"
                                : "cursor-default"
                            } ${
                              route?.path !== "" && isActive
                                ? "border-r-4 border-black text-headerText font-semibold"
                                : "text-sidebarText"
                            }`}
                            onClick={handleClickAway}
                          >
                            <div className={`flex items-center gap-3`}>
                              <div className="">
                                <Icon
                                  className="w-6 h-5"
                                  aria-hidden="true"
                                  icon={route?.icon ? route.icon : ""}
                                />
                              </div>
                              <div className="text-sm">{route.name}</div>
                            </div>
                          </li>
                        )}
                      </NavLink>
                      <ul className="mb-2">
                        {route?.subRoutes
                          ? route?.subRoutes?.map((subRoute, i) => (
                              <NavLink
                                key={subRoute?.name}
                                to={subRoute?.path !== "" && subRoute?.path}
                              >
                                {({ isActive }) => (
                                  <li
                                    className={`pl-[70px] py-2 ${
                                      subRoute?.path !== ""
                                        ? "cursor-pointer"
                                        : "cursor-default"
                                    } ${
                                      subRoute?.path !== "" && isActive
                                        ? "border-r-4 border-black text-headerText font-semibold"
                                        : "text-sidebarText"
                                    }`}
                                    onClick={handleClickAway}
                                  >
                                    <div className={`flex items-center gap-3 `}>
                                      <div className="">
                                        <Icon
                                          className="w-6 h-5"
                                          aria-hidden="true"
                                          icon={
                                            subRoute?.icon ? subRoute?.icon : ""
                                          }
                                        />
                                      </div>
                                      <div className="text-sm">
                                        {subRoute?.name}
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </NavLink>
                            ))
                          : ""}
                      </ul>
                    </div>
                  ))}
                </ul>
                <div className="flex flex-col justify-end items-start h-full w-full">
                  <ul className="w-full">
                    {menuItems?.map((menuItem, index) => (
                      <NavLink
                        key={index}
                        to={menuItem?.path !== "" && menuItem?.path}
                      >
                        {({ isActive }) => (
                          <li
                            className={`px-4 py-2 mb-2 w-full cursor-pointer ${
                              menuItem.path !== "" && isActive
                                ? "border-r-4 border-black text-headerText font-semibold"
                                : "text-sidebarText"
                            }`}
                            onClick={() => {
                              if (menuItem?.name === "Logout") {
                                handleLogout();
                              } else handleClickAway();
                            }}
                          >
                            <div className={`flex items-center gap-3`}>
                              <div className="">
                                <Icon
                                  className="w-6 h-5"
                                  aria-hidden="true"
                                  icon={menuItem?.icon ? menuItem?.icon : ""}
                                />
                              </div>
                              <div className="text-sm">{menuItem?.name}</div>
                            </div>
                          </li>
                        )}
                      </NavLink>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};

export default MobileSidebar;
