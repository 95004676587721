import { Skeleton } from "@mui/material";
import React from "react";

const HeaderSkltn = () => {
  return (
    <div className="flex gap-3 md:gap-5 items-center justify-end py-4 px-6">
      <div>
        <Skeleton variant="circular" width={30} height={30} />
      </div>
      <hr className="rotate- border border-black h-12" />
      <div className="flex items-center gap-3">
        <Skeleton variant="circular" width={40} height={40} />
        <div className="hidden md:block">
          <Skeleton variant="text" width={60} height={10} />
          <Skeleton variant="text" width={60} height={10} />
        </div>
      </div>
      <div className="hidden md:block">
        <Skeleton variant="text" width={20} height={20} />
      </div>
    </div>
  );
};

export default HeaderSkltn;
