import React, { useContext, useState } from "react";
import {
  NotificationIcon,
  DownArrowIcon,
  LogoutIcon,
  QliqleLogo,
  SiteIcon,
} from "../icons";
import Avatar from "@mui/material/Avatar";
import { AuthContext } from "../context/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderSkltn from "./Skeloton/HeaderSkltn";
import CustomTooltip from "./CustomTooltip";
import PopoverComponent from "./Popover/Popover";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { logout, userData, handleMobileSidebarOpen, userListLoading } =
    useContext(AuthContext);

  const getTooltipContent = () => (
    <div className="flex flex-col gap-2 ">
      <span className="text-headerText font-semibold text-sm">
        Name: <span className="text-sidebarText">{userData?.data?.name}</span>
      </span>
      <span className="text-headerText font-semibold text-sm">
        Role:{" "}
        <span className="text-sidebarText">{userData?.data?.role?.name}</span>
      </span>
    </div>
  );
  const navigate = useNavigate();

  const menuItems = [
    // { title: "Profile", icon: <YourProfileIconComponent /> },
    { title: "My Profile", icon: <SiteIcon className="w-6 h-5" /> },
    { title: "Logout", icon: <LogoutIcon className="w-6 h-5" /> },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleMenuItemClick = (title) => {
    if (title === "Logout") {
      handleLogout();
    } else if (title === "My Profile") {
      navigate("/app/my-profile");
      handleClose();
    } else if (title === "Notification") {
      navigate("/notifications");
      handleClose();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setPopoverOpen(false);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <header className="w-full bg-bgContainer px-5 ">
      {userListLoading ? (
        <HeaderSkltn />
      ) : (
        <div className="flex w-full items-center py-4 justify-between md:justify-end">
          <div className="flex gap-4 items-center md:hidden">
            <MenuIcon
              className="cursor-pointer"
              onClick={() => handleMobileSidebarOpen()}
            />
            <QliqleLogo className="w-28 h-14 " />
          </div>
          <div className="flex h-full gap-3 items-center ">
            <NotificationIcon />
            <hr className="border border-black h-12" />
            <div className="block md:hidden">
              <CustomTooltip
                placement={"bottom-end"}
                title={getTooltipContent()}
              >
                <Avatar />
              </CustomTooltip>
            </div>
            <div className="hidden md:block">
              <Avatar />
            </div>
            <div className="hidden md:block">
              <div className="flex items-center">
                <div className="flex text-headerText flex-col justify-around">
                  <label className="font-semibold">
                    {userData?.data?.name}
                  </label>
                  <label className="text-xs -mt-1">
                    {userData?.data?.role?.name}
                  </label>
                </div>
                <div
                  className={`ml-5 cursor-pointer ${
                    open ? "rotate-0" : "rotate-180"
                  }`}
                  onClick={handleIconClick}
                >
                  <DownArrowIcon />
                </div>
              </div>
              <PopoverComponent
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                // transformOrigin={{
                //   vertical: "top",
                //   horizontal: "left",
                // }}
                anchorEl={anchorEl}
                onClose={handleClose}
                open={popoverOpen}
                //handleClick={handleLogout}
                menuItems={menuItems}
                handleMenuItemClick={handleMenuItemClick}
              />
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
