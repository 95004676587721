import * as Yup from "yup";
import { emailRegex, restrictedChars, specialChars } from "./constants";
import { usePhoneInputStore } from "../store/phoneInputStore";

const ValidationSchema = () => {
  function validateNumber(value) {
    const isNumeric = (value) => {
      return /^\d+$/.test(value);
    };

    const { countryName, phoneLength, countryCode } = this.parent;

    if (!value) {
      return this.createError({
        message: "Phone number is required",
      });
    } else if (!isNumeric(value)) {
      return this.createError({
        message: "Only numbers allowed",
      });
    } else if (value.toString().length > phoneLength) {
      return this.createError({
        message: `Enter Correct Number.Length should be ${phoneLength} for (+${countryCode}) ${countryName}`,
      });
    } else if (value.toString().length < phoneLength) {
      return this.createError({
        message: `Enter Correct Number.Length should be ${phoneLength} for (+${countryCode}) ${countryName}`,
      });
    }
    return true;
  }

  const fullnameValidation = Yup.string()
    .min(3, "Min length allowed is 3 characters")
    .matches(/^[^_ ]/, "Name must not start with a space or underscore")
    .matches(/^[ A-Za-z_]+$/, "Only spaces and underscores are allowed")
    .max(32, "Max length allowed is 32 characters");

  const emailValidation = Yup.string()
    .max(64, "Max length allowed is 64 characters")
    .matches(emailRegex, {
      message: "Must be valid email",
    });

  // .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, {
  //   message: "Must be valid email",
  // });

  const companyNameValidation = Yup.string()
    .matches(/^\S/, "Name shouldn't start with special characters")
    .min(3, "Min length allowed is 3 character")
    .max(32, "Max length allowed is 32 characters");

  const descriptionValidation = Yup.string()
    .min(8, "Min length allowed is 8 character")
    .max(50, "Max length allowed is 50 characters");

  const CurrentLocationValidation = Yup.string()
    .matches(/^\S/, "Location shouldn't start with special characters")
    .max(25, "Location must not exceed 25 characters");

  const passwordValidation = Yup.string()
    .required("Password is required")
    .matches(/[A-Z]/, "Password must include at least one uppercase letter")
    .matches(/[a-z]/, "Password must include at least one lowercase letter")
    .matches(/[0-9]/, "Password must include at least one number")
    // .test('no-restricted-chars', `Password must not contain restricted characters ${restrictedChars}`, value =>
    //     !restrictedChars.test(value || '')
    //   )
    .matches(
      specialChars,
      "Password must include at least one special character"
    )
    .min(8, "Password must be at least 8 characters long")
    .max(32, "Max length allowed is 32 characters");

  const roleNameValidation = Yup.string()
    .matches(/^\S/, "Name shouldn't start with special characters")
    .min(3, "Role Name must be at least 3 characters")
    .max(32, "Role Name must not exceed 32 characters");

  const PartnerNameValidation = Yup.string()
    .matches(/^\S/, "Name shouldn't start with special characters")
    .min(3, "Partner Name must be at least 3 characters")
    .max(32, "Partner Name must not exceed 32 characters");

  const AboutCompanyValidation = Yup.string()
    .matches(/^\S/, "Details shouldn't start with special characters")
    .max(50, "Company details must not exceed 50 characters");

  const phoneNumberValidation = Yup.string().test(
    "custom-validation",
    "Custom validation failed",
    validateNumber
  );
  const photoCountValidation = Yup.number()
    .typeError("Photo count must be a number")
    .integer("Photo count must be an integer")
    .positive("Photo count must be a positive number");

  return {
    fullnameValidation,
    emailValidation,
    companyNameValidation,
    passwordValidation,
    descriptionValidation,
    roleNameValidation,
    PartnerNameValidation,
    phoneNumberValidation,
    AboutCompanyValidation,
    CurrentLocationValidation,
    photoCountValidation,
  };
};
export default ValidationSchema;
