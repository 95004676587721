export const BASE_URL = process.env.REACT_APP_DEVELOPMENT_API_BASE_URL;

const APIMapping = {
  //These end points are used for Onboarding a user
  accounts: {
    signUp: BASE_URL + "/qliqle_accounts/api/v1/accounts/_signup",
    setPassword: BASE_URL + "/qliqle_accounts/api/v1/accounts/pswd/_reset",
    login: BASE_URL + "/qliqle_accounts/api/v1/accounts/_login",
    mobileVerify: BASE_URL + "/qliqle_accounts/api/v1/accounts/_sendOTP",
    otpVerify: BASE_URL + "/qliqle_accounts/api/v1/accounts/_verifyMobile",
    resendVerifyLink:
      BASE_URL + "/qliqle_notifications/api/v1/notification/alink/_send",
    resendLinkForResetPassword:
      BASE_URL + "/qliqle_accounts/api/v1/accounts/pswd/_sendResetalink",
    getAccessToken:
      BASE_URL + "/qliqle_accounts/api/v1/accounts/_refresh-token",
    uploadDocument: BASE_URL + "/qliqle_main/api/v1/document/upload",
    getAllUploadedDocumentList:
      BASE_URL + "/qliqle_main/api/v1/document/get_all_docs",
    deleteDocById: BASE_URL + "/qliqle_main/api/v1/document/docs/{doc_id}",
  },

  //These end points used for User management functions
  userManagement: {
    getLeftMenu: BASE_URL + "/qliqle_role/api/v1/roles/menu",
    createUser: BASE_URL + "/qliqle_accounts/api/v1/accounts/users",
    deleteUser: BASE_URL + "/qliqle_accounts/api/v1/accounts/users/{userId}",
    editUser: BASE_URL + "/qliqle_accounts/api/v1/accounts/users/{userId}",
    userActiveUpdate:
      BASE_URL + "/qliqle_accounts/api/v1/accounts/users/activate/{userId}",
    userInactiveUpdate:
      BASE_URL + "/qliqle_accounts/api/v1/accounts/users/deactivate/{userId}",
    getUserAccount: BASE_URL + "/qliqle_accounts/api/v1/accounts/userDetails",
  },

  //These end points used for Role management functions
  roleManagement: {
    getAllRoles: BASE_URL + "/qliqle_role/api/v1/accounts/roles",
    getRole: BASE_URL + "/qliqle_role/api/v1/accounts/roles/{roleId}",
    getTemplate: BASE_URL + "/qliqle_role/api/v1/resources/templates",
    // getTemplates : BASE_URL + "qliqle_role/api/v1/resources/templates/{}"
  },

  //These end points used for Partner management functions
  partnerManagement: {
    getAllPartners: BASE_URL + "/qliqle_accounts/api/v1/accounts/partner",
    getPartnerById:
      BASE_URL + "/qliqle_accounts/api/v1/accounts/partner/{partnerId}",
    editPartnerById: BASE_URL + "/qliqle_accounts/api/v1/accounts/partner",
    deletePartner:
      BASE_URL + "/qliqle_accounts/api/v1/accounts/partner/{partnerId}",
  },

  //These end points used for Site management functions
  siteManagement: {
    getAllSitesByCompId: BASE_URL + "/qliqle_main/api/v1/company/sites",
    getAllSites: BASE_URL + "/qliqle_main/api/v1/company/sites/all",
    siteActions: BASE_URL + "/qliqle_main/api/v1/company/sites/{siteId}",
  },
  cameraManagement: {
    getAllSitesCamera: BASE_URL + "/qliqle_main/api/v1/company/cameras/all",
    getCamera: BASE_URL + "/qliqle_main/api/v1/company/sites/add-camera",
    getAllCamera:
      BASE_URL + "/qliqle_main/api/v1/company/sites/{siteId}/cameras",
    editCamera:
      BASE_URL +
      "/qliqle_main/api/v1/company/sites/{siteId}/cameras/{cameraId}",
  },

  //These end points used for Face id feature
  faceId: {
    uploadImage: BASE_URL + "/qliqle_webuser/api/v1/photos/upload",
    photoList: BASE_URL + "/qliqle_webuser/api/v1/photos/all",
    photoDetailsById: BASE_URL + "/qliqle_webuser/api/v1/photos/detail/{ptoId}",
    faceDetailsById:
      BASE_URL +
      "/qliqle_webuser/api/v1/photos/face/similar_faces/{faceId}/{userId}",
    deleteSite: BASE_URL + "/qliqle_main/api/v1/company/sites/{siteId}",
    editSite: BASE_URL + "/qliqle_main/api/v1/company/sites/{siteId}",
    resetUserData:
      BASE_URL +
      "/qliqle_accounts/api/v1/appUser/delete/photoMeteData/FaceIdentificationUrl/all",
    resetUserByNumber:
      BASE_URL + "/qliqle_appuser/api/v1/appUser/deleteUserByNumber",
    updateFaceSetting: BASE_URL + "/qliqle_accounts/api/v1/settings/",
    getImageSetting: BASE_URL + "/qliqle_accounts/api/v1/settings/image",
    getFaceSetting: BASE_URL + "/qliqle_accounts/api/v1/settings/face",
    getAllPhotoList: BASE_URL + "/qliqle_webuser/api/v1/photos",
    addNameForFace: BASE_URL + "/qliqle_appuser/api/v1/photos/face/add_name",
    faceConfiguration:
      BASE_URL + "/qliqle_main/api/v1/face_recognition/no_of_photos_to_process",
  },

  dashbord: {
    getUsersCount: BASE_URL + "/qliqle_main/api/v1/dashboard/users",
    getUsersActions: BASE_URL + "/qliqle_main/api/v1/dashboard/actions",
    getOsUsersCount: BASE_URL + "/qliqle_main/api/v1/dashboard/Os/allDetails",
    getTopDeviceDetails:
      BASE_URL + "/qliqle_main/api/v1/dashboard/Os/topDeviceModels",
    getActions: BASE_URL + "/qliqle_dshbapi/api/v1/dashboard/actions",
    getUser:
      BASE_URL +
      "/qliqle_dshbapi/api/v1/dashboard/photos/get-photo-count-per-user",
    getAllUsers:
      BASE_URL + "/qliqle_main/api/v1/dashboard/user/get-all-user-details",
    getDataPerUser: BASE_URL + "/qliqle_main/api/v1/userReports/users/{userId}",
  },
  reports: {
    getAllUseReport:
      BASE_URL +
      "/qliqle_main/api/v1/userReports/users/{userId}/userTotalReport",
  },
};

const getApiMap = (api) => {
  return APIMapping[api];
};

export default getApiMap;
