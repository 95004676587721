import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// import Login from "../pages/Signin";
// import Signup from "../pages/Signup";
// import ForgetPassword from "../pages/ForgetPassword";
// import CreatePassword from "../pages/CreatePassword";
// import ResetPassword from "../pages/ResetPassword";
// import MobileVerification from "../pages/MobileNumberVerification";
// import ResendLinkScreen from "../components/ResendLinkScreen";

const Login = lazy(() => import("../pages/Signin"));
const Signup = lazy(() => import("../pages/Signup"));
const ForgetPassword = lazy(() => import("../pages/ForgetPassword"));
const CreatePassword = lazy(() => import("../pages/CreatePassword"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const MobileVerification = lazy(() => import("../pages/MobileNumberVerification"));
const ResendLinkScreen = lazy(() => import("../components/ResendLinkScreen"));
// const ForgetPassword = lazy(() => import("../pages/ForgetPassword"));

const Auth = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route
          path="/create-password/:userId/:token/_vlink"
          element={<CreatePassword />}
        />
        <Route path="/reset-password/:userId/:token/_rlink" element={<ResetPassword />} />
        <Route path="/mobile-verify" element={<MobileVerification />} />
        <Route path="/resent-link/:key" element={<ResendLinkScreen />} />
        <Route path="/" element={<Navigate to="/auth/login" />} />
      </Routes>
    </>
  );
};

export default Auth;
