import { CircularProgress } from "@mui/material";
import React from "react";

const CircularLoading = (props) => {
  const { size, color, ...rest } = props;
  return (
    <div>
      <CircularProgress
        size={size ? size : 35}
        sx={{ color: color ? color : "#0F0F0F" }}
      />
    </div>
  );
};

export default CircularLoading;
