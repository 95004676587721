import React from "react";
import { Skeleton } from "@mui/material";

function SidebarSkltn() {
  return (
    <div>
      <div className="w-full grid">
        <div className="flex flex-col gap-6 mt-8 items-start pl-10">
        <div className="flex gap-3">
          <Skeleton variant="text" width="5" className="w-5"/>
          <Skeleton variant="text" width="32" className="w-24" />
        </div>
        <div className="flex gap-3">
          <Skeleton variant="text" width="5" className="w-5"/>
          <Skeleton variant="text" width="32" className="w-32" />
        </div>
        <div className="flex gap-3">
          <Skeleton variant="text" width="5" className="w-5"/>
          <Skeleton variant="text" width="32" className="w-36" />
        </div>
        <div className="flex gap-3">
          <Skeleton variant="text" width="5" className="w-5"/>
          <Skeleton variant="text" width="32" className="w-40" />
        </div>
        <div className="flex gap-3">
          <Skeleton variant="text" width="5" className="w-5"/>
          <Skeleton variant="text" width="32" className="w-36" />
        </div>
        <div className="flex gap-3 pl-5">
          <Skeleton variant="text" width="5" className="w-5"/>
          <Skeleton variant="text" width="32" className="w-20" />
        </div>
        <div className="flex gap-3 pl-5">
          <Skeleton variant="text" width="5" className="w-5"/>
          <Skeleton variant="text" width="32" className="w-20" />
        </div>
        <div className="flex gap-3">
          <Skeleton variant="text" width="5" className="w-5"/>
          <Skeleton variant="text" width="32" className="w-28" />
        </div>
        <div className="flex gap-3">
          <Skeleton variant="text" width="5" className="w-5"/>
          <Skeleton variant="text" width="32" className="w-28" />
        </div>
        <div className="flex gap-3">
          <Skeleton variant="text" width="5" className="w-5"/>
          <Skeleton variant="text" width="32" className="w-28" />
        </div>
      </div>
      </div>
    </div>
  );
}

export default SidebarSkltn;
