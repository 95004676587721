import logo from "./logo.svg";
import "./App.css";
import { useTranslation } from "react-i18next";
import { lazy, useEffect } from "react";
// import { Route, Router, Routes } from 'react-router';
import SigninPage from "./pages/Signin";
// import Auth from '../src/container/Auth';
import { useMyContext } from "./context/AuthContext";
import routes from "./routes";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import ProtectedRoutes from "./components/Routes/ProtectedRoutes";
import ForwardRoute from "./components/Routes/ForwardRoute";
// const SigninForm = lazy(() => import("./pages/Signin"));

function App() {
  const { i18n } = useTranslation();
  const val = i18n.resolvedLanguage;

  useEffect(() => {
    if (val === "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
  }, [val, i18n]);

  return (
    // <RouterProvider router={routes}/>
    <>
      <Router>
        <Routes>
          <Route path="/auth/*" element={<ForwardRoute />} />
          <Route path="/app/*" element={<ProtectedRoutes />} />

          <Route path="*" element={<Navigate to="/auth" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
