import React from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

const InputLabel = ({ label, labelClassName, isMandatory }) => {
  const { t } = useTranslation();

  return (
    <p
      className={twMerge(
        `text-grayPrimary text-sm font-medium mb-1`,
        labelClassName
      )}
    >
      {t(label)}
      {isMandatory ? (
        <span className="text-grayPrimary text-sm font-medium">*</span>
      ) : null}
    </p>
  );
};

export default InputLabel;
