import React from "react";
import { Link, useNavigate } from "react-router-dom";

// import { ForbiddenIcon } from "../icons";

function Page404() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This will go back to the previous page in the history stack
  };

  return (
    <div className="flex flex-col items-center mt-5">
      <h1
        className="font-semibold text-gray-700"
        style={{ fontSize: "clamp(50px, 5vw, 60px)" }}
      >
        404
      </h1>
      <p className="text-gray-700 ">
        Page not found. Check the address or{" "}
        <span
          className="text-purple-600 hover:underline cursor-pointer "
          onClick={handleGoBack}
        >
          go back
        </span>
        .
      </p>
    </div>
  );
}

export default Page404;
