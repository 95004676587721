import axios from "axios";
import getApiMap from "../routes/ApiUrl";
import getKrakendApiMap from "../routes/KrakendUrl";

//This method used to get sidebar menu list
// export async function getSidebarMenu() {
//   let url = getApiMap("accounts").getLeftMenu;
//   // console.log(url);
//   let response = axios.get(url);
//   return response;
// }

//This method used to create a User
// export async function createUserAPI(option) {
//   let url = getApiMap("userManagement").createUser;
//   let response = axios.post(url, option.payload);
//   return response;
// }

//This method used to list a all User
// export async function getAllUsers(options) {
//   let url = getApiMap("userManagement").createUser;
//   if (options.queryKey[1] && options.queryKey[1] !== "") {
//     url += "?pgsize=" + options.queryKey[1];
//   }
//   if (options.queryKey[2] && options.queryKey[2] !== "") {
//     url += "&pgindex=" + options.queryKey[2];
//   }
//   // url += "?pgsize=" + options.queryKey[1];
//   // url += "&pgindex=" + options.queryKey[2];
//   if (options.queryKey[3] && options.queryKey[3] !== "") {
//     url += "&search=" + options.queryKey[3];
//   }
//   let response = axios.get(url);
//   return response;
// }

//This method used to delete a User by ID
// export async function deleteUserById(options) {
//   let url = getApiMap("userManagement").deleteUser;
//   url = url.replace("{userId}", options.userId);
//   let response = axios.delete(url);
//   return response;
// }

//This method used to Edit a User by ID
// export async function editUserById(options) {
//   let url = getApiMap("userManagement").editUser;
//   url = url.replace("{userId}", options.userId);
//   let response = axios.put(url, options.payload);
//   return response;
// }

//This method used to Activate user by ID
// export async function userActiveUpdateById(options) {
//   let url = getApiMap("userManagement").userActiveUpdate;
//   url = url.replace("{userId}", options.userId);
//   let response = axios.put(url, options.payload);
//   return response;
// }

//This method used to Inctivate user by ID
// export async function userInactiveUpdateById(options) {
//   let url = getApiMap("userManagement").userInactiveUpdate;
//   url = url.replace("{userId}", options.userId);
//   let response = axios.put(url, options.payload);
//   return response;
// }

// export async function getUserDetails() {
//   let url = getApiMap("userManagement").getUserAccount;
//   // console.log(url);
//   let response = axios.get(url);
//   return response;
// }

//This method used to get a reports for a user
// export async function getUserReports(options) {
//   let url = getApiMap("reports").getAllUseReport;
//   url = url.replace("{userId}", options.queryKey[1]);
//   // console.log(url);
//   let response = axios.get(url);
//   return response;
// }

/************************ KrakenD API's ****************************/

//This method used to get sidebar menu list
export async function getSidebarMenu() {
  let url = getKrakendApiMap("getLeftMenu");
  // console.log(url);
  let response = axios.get(url);
  return response;
}

//This method used to get a reports for a user
export async function getUserReports(options) {
  let url = getKrakendApiMap("getAllUseReport");
  url = url.replace("{userId}", options.queryKey[1]);
  // console.log(url);
  let response = axios.get(url);
  return response;
}

//This method used to create a User
export async function createUserAPI(option) {
  let url = getKrakendApiMap("addOrGetUser");
  let response = axios.post(url, option.payload);
  return response;
}

//This method used to list a all User
export async function getAllUsers(options) {
  let url = getKrakendApiMap("addOrGetUser");

  if (options.queryKey[1] && options.queryKey[1] !== "") {
    url += "?pgsize=" + options.queryKey[1];
  }
  if (options.queryKey[2] && options.queryKey[2] !== "") {
    url += "&pgindex=" + options.queryKey[2];
  }
  if (options.queryKey[3] && options.queryKey[3] !== "") {
    url += "&search=" + options.queryKey[3];
  }

  let response = axios.get(url);
  return response;
}

//This method used to delete a User by ID
export async function deleteUserById(options) {
  let url = getKrakendApiMap("userOptions");
  url = url.replace("{user_id}", options.userId);
  let response = axios.delete(url);
  return response;
}

//This method used to Edit a User by ID
export async function editUserById(options) {
  let url = getKrakendApiMap("userOptions");
  url = url.replace("{user_id}", options.userId);
  let response = axios.put(url, options.payload);
  return response;
}

//This method used to Get a User by ID
export async function getUserById(options) {
  let url = getKrakendApiMap("userOptions");
  url = url.replace("{user_id}", options.userId);
  let response = axios.get(url);
  return response;
}

//This method used to Activate user by ID
export async function userActiveUpdateById(options) {
  let url = getKrakendApiMap("userActivation");
  url = url.replace("{user_id}", options.userId);
  let response = axios.put(url, options.payload);
  return response;
}

//This method used to Inctivate user by ID
export async function userInactiveUpdateById(options) {
  let url = getKrakendApiMap("userDeactivation");
  url = url.replace("{userId}", options.userId);
  let response = axios.put(url, options.payload);
  return response;
}

//This method used to get header contents
export async function getUserDetails() {
  let url = getKrakendApiMap("getUserDetails");
  let response = axios.get(url);
  return response;
}
