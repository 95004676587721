import axios from "axios";
import getApiMap from "../routes/ApiUrl";
import getKrakendApiMap from "../routes/KrakendUrl";

//This method used to Signup a user
// export async function signupRequest(option) {
//   const url = getApiMap("accounts").signUp;
//   const response = axios.post(url, option.payload);
//   return response;
// }

//This method used to Set a password for a user
// export async function setPassword(option) {
//   const url = getApiMap("accounts").setPassword;
//   const response = axios.post(url, option.payload);
//   return response;
// }

//This method used to Login a user
// export async function loginRequest(option) {
//   const url = getApiMap("accounts").login;
//   const response = axios.post(url, option.payload);
//   return response;
// }

//This method used to Verify mobile number
// export async function mobileNumberVerify(option) {
//   const url = getApiMap("accounts").mobileVerify;
//   const response = axios.post(url, option.payload);
//   return response;
// }

//This method used to verify OTP
// export async function otpVerify(option) {
//   const url = getApiMap("accounts").otpVerify;
//   const response = axios.post(url, option.payload);
//   return response;
// }

//This method used to Resend a verification link for email
// export async function resendVerifyLink(option) {
//   const url = getApiMap("accounts").resendVerifyLink;
//   const response = axios.post(url, option.payload);
//   return response;
// }
// export async function resetPassword(option) {
//   const url = getApiMap("accounts").resetPassword;
//   const response = axios.post(url, option.payload);
//   return response;
// }

//This method used to Resend a link for reset a password
// export async function resendLinkForResetPassword(option) {
//   const url = getApiMap("accounts").resendLinkForResetPassword;
//   const response = axios.post(url, option.payload);
//   return response;
// }

//This method used to get a Access token by using refresh token
// export async function getAccessToken() {
//   let url = getApiMap("accounts").getAccessToken;
//   // console.log(url);
//   let response = axios.get(url);
//   return response;
// }

//This method used to upload a document for Application homepage
// export async function uploadDocument(options) {
//   const header = {
//     "Content-Type": "multipart/form-data",
//   };
//   let url = getApiMap("accounts").uploadDocument;
//   let formData = new FormData();
//   // options.payload.forEach((document) => formData.append("document", document));
//   formData.append("document", options.payload.document);
//   formData.append("app_page", options.payload.app_page);
//   // console.log(url);
//   let response = axios.post(url, formData, header);
//   return response;
// }

//This method used to upload a document for Application homepage
// export async function getAllDocumentList(options) {
//   let url = getApiMap("accounts").getAllUploadedDocumentList;
//   url += `?pgsize=${options.queryKey[1]}&pgindex=${options.queryKey[2]}`;
//   // console.log(url);
//   let response = axios.get(url);
//   return response;
// }

//This method used to upload a document for Application homepage
// export async function deleteDocById(options) {
//   let url = getApiMap("accounts").deleteDocById;
//   // console.log(url);
//   url = url.replace("{doc_id}", options.payload.doc_id);
//   let response = axios.delete(url);
//   return response;
// }

//This method used to upload a document for Application homepage
export async function getDocById(options) {
  console.log(options);
  let url = getApiMap("accounts").deleteDocById;
  // console.log(url);
  url = url.replace("{doc_id}", options.queryKey[1]);
  let response = axios.get(url);
  return response;
}

/****************************** KrakenD url foe Onboarding API's *************************************/

//This method used to Signup a user
export async function signupRequest(option) {
  const url = getKrakendApiMap("register");
  const response = axios.post(url, option.payload);
  return response;
}

//This method used to Set a password for a user
export async function setPassword(option) {
  const url = getKrakendApiMap("setPassword");
  const response = axios.post(url, option.payload);
  return response;
}

//This method used to Login a user
export async function loginRequest(option) {
  const url = getKrakendApiMap("login");
  const response = axios.post(url, option.payload);
  return response;
}

//This method used to Verify mobile number
export async function mobileNumberVerify(option) {
  const url = getKrakendApiMap("sendOtp");
  const response = axios.post(url, option.payload);
  return response;
}

//This method used to verify OTP
export async function otpVerify(option) {
  const url = getKrakendApiMap("verifyMobile");
  const response = axios.post(url, option.payload);
  return response;
}

//This method used to Resend a verification link for email
export async function resendVerifyLink(option) {
  const url = getKrakendApiMap("resendVerifyLink");
  const response = axios.post(url, option.payload);
  return response;
}

//This method used to Resend a link for reset a password
export async function resendLinkForResetPassword(option) {
  const url = getKrakendApiMap("resendLinkForResetPassword");
  const response = axios.post(url, option.payload);
  return response;
}

//This method used to upload a document for Application homepage
export async function uploadDocument(options) {
  const header = {
    "Content-Type": "multipart/form-data",
  };
  let url = getKrakendApiMap("uploadDocument");
  let formData = new FormData();
  // options.payload.forEach((document) => formData.append("document", document));
  formData.append("document", options.payload.document);
  formData.append("app_page", options.payload.app_page);
  // console.log(url);
  let response = axios.post(url, formData, header);
  return response;
}

//This method used to upload a document for Application homepage
export async function getAllDocumentList(options) {
  let url = getKrakendApiMap("getAllUploadedDocumentList");
  url += `?pgsize=${options.queryKey[1]}&pgindex=${options.queryKey[2]}`;
  // console.log(url);
  let response = axios.get(url);
  return response;
}

//This method used to upload a document for Application homepage
export async function deleteDocById(options) {
  let url = getKrakendApiMap("deleteDocById");
  // console.log(url);
  url = url.replace("{document_id}", options.payload.doc_id);
  let response = axios.delete(url);
  return response;
}

export async function getAccessToken() {
  let url = getKrakendApiMap("getAccessToken");
  // console.log(url);
  let response = axios.get(url);
  return response;
}
