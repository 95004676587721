import React, { useState } from "react";
import SigninForm from "../components/Forms/SigninForm";
// import takeSelfieIcon from "../assets/imgs/takeSelfie.png";
import { TakingPicturekIcon } from "../icons";
import LoginTemplate from "../components/LoginTemplate";
import FullScreenError from "../components/FullScreenError";
import { Link } from "react-router-dom";
import SignupSuccess from "../components/SignupSuccess";

const SigninPage = () => {
  const [succesScreen, setSuccessScreen] = useState(false);
  const [errorScreen, setErrorScreen] = useState(false);
  return (
    <>
      <LoginTemplate
        rightPanelIconSvg={<TakingPicturekIcon className="w-full" />}
        IconClassname={"mt-2"}
      >
        <h1 className="text-grayPrimary text-4xl font-bold mb-2">Login</h1>
        <label className="text-graySecondary text-base font-semibold">
          Let’s Login your Qliqle account.
        </label>
        <SigninForm
          setErrorScreen={setErrorScreen}
          setSuccessScreen={setSuccessScreen}
        />
        <div className="mt-2 text-grayPrimary text-sm font-normal">
          Don’t have an account?{" "}
          <Link to={"/auth/signup"}>
            <span className="text-bluePrimary text-sm font-medium cursor-pointer">
              Sign up
            </span>
          </Link>
        </div>
      </LoginTemplate>
      <SignupSuccess
        open={succesScreen}
        action={() => setSuccessScreen(false)}
      />
      <FullScreenError
        open={errorScreen}
        action={() => setErrorScreen(false)}
      />
    </>
  );
};

export default SigninPage;
