import React from "react";
import { PlusIcon } from "../../icons";
import { twMerge } from "tailwind-merge";
import CircularLoading from "../LoadingState/CircularLoading";

const FilledButton = ({ buttonClassName, isLoading, isAddIcon, ...rest }) => {
  return (
    <div>
      {!isAddIcon ? (
        <button
          {...rest}
          className={twMerge(
            `w-full h-11 duration-300 bg-zinc-800 text-white font-medium text-sm rounded-lg disabled:bg-opacity-70 `,
            buttonClassName
          )}
        >
          {isLoading ? (
            <CircularLoading color={"#C4C4C4"} />
          ) : (
            <span>{rest.label}</span>
          )}
        </button>
      ) : (
        <button
          {...rest}
          className={`flex gap-3 justify-center items-center h-11 bg-zinc-800 text-white font-medium text-sm rounded-lg disabled:bg-opacity-70 ${buttonClassName}`}
        >
          <span>{rest.label}</span>
          <PlusIcon />
        </button>
      )}
    </div>
  );
};

export default FilledButton;
