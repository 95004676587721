import React, { useContext } from "react";
import Auth from "../../container/Auth";
import { AuthContext } from "../../context/AuthContext";
import { Navigate } from "react-router-dom";

const ForwardRoute = () => {
  const { userId, session } = useContext(AuthContext);
  return (
    <>
      {session ? (
        // <ProtectedRoutes />
        <Navigate to={"/app/home"} />
      ) : (
        <Auth />
      )}
    </>
  );
};

export default ForwardRoute;
