import React from "react";
import { CrossWhiteIcon } from "../icons";
import { RobotIcon } from "../icons";

const FullScreenError = ({ open, action }) => {
  return (
    <>
      {open ? (
        <div className="absolute top-0 min-h-screen w-full bg-bgSuccess">
          <div className="absolute right-8 top-3 sm:right-12 sm:top-5 md:right-16 md:top-7 lg:right-24 lg:top-10">
            <CrossWhiteIcon
              className="w-7 h-7 md:w-10 md:h-10 cursor-pointer"
              onClick={action}
            />
          </div>
          <div className="h-screen flex flex-col justify-center">
            <div className="flex flex-col py- justify-center items-center px-5">
              <RobotIcon />
              <label className="mt-[4%] text-white text-4xl font-bold">
                Oops...
              </label>
              <label className="text-white text-base font-normal mt-4 text-center">
                Your account is not register with us, please click on the below
                link to register!
              </label>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FullScreenError;
