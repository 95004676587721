import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { BASE_URL } from "../routes/ApiUrl";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { getSidebarMenu, getUserDetails } from "../lib/userManagement-api";
import { getRoute } from "../routes";
import Page500 from "../pages/500Error";
import getKrakendApiMap from "../routes/KrakendUrl";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState();
  const [expireTime, setExpireTime] = useState();
  const [userId, setUserId] = useState(
    window.localStorage.getItem("userId") || null
  );
  const [accessToken, setAccessToken] = useState(
    window.localStorage.getItem("accessToken") || null
  );
  const [refreshToken, setRefreshToken] = useState(
    window.localStorage.getItem("refreshToken") || null
  );
  const [session, setSession] = useState(
    JSON.parse(window.localStorage.getItem("session")) || null
  );
  const [userMailId, setUserMailId] = useState(
    window.localStorage.getItem("userMailId") || null
  );
  const [userName, setUserName] = useState(
    window.localStorage.getItem("userName") || null
  );

  const [userData, setUserData] = useState(null);
  const [sideMenuList, setSideMenuList] = useState(null);

  const setSessionValue = (newValue) => {
    setSession((val) => newValue);
  };
  const [routes, setRoutes] = useState([]);
  const [openMobileSidebar, setOpenMobileSidebar] = useState(false);

  const handleMobileSidebarOpen = () => {
    setOpenMobileSidebar(!openMobileSidebar);
  };

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(
      (config) => {
        config.withCredentials = false;
        config.credentials = "include";
        if (session) {
          config.headers.Authorization = `Bearer ${session.accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(interceptorId);
    };
  }, [session]);

  useEffect(() => {
    window.localStorage.setItem("userId", userId);
    window.localStorage.setItem("accessToken", accessToken);
    window.localStorage.setItem("refreshToken", refreshToken);
    window.localStorage.setItem("userMailId", userMailId);
    window.localStorage.setItem("userName", userName);
    window.localStorage.setItem("session", JSON.stringify(session));
  }, [userId, userMailId, refreshToken, accessToken, userName, session]);

  const refreshTokens = useCallback(() => {
    if (session) {
      return (
        axios
          // .post(`${BASE_URL}/api/v1/accounts/refreshtoken`, {
          .post(getKrakendApiMap("getAccessToken"), {
            refresh_token: session.refreshToken,
          })
          .then((response) => {
            setSessionValue({
              accessToken: response?.data?.access_token,
              refreshToken: response?.data?.refresh_token,
            });
            setExpireTime(response?.data?.refresh_expires_in);
            // setLoaded(true)
            return response;
          })
          .catch((error) => {
            setSessionValue(null);
            return error;
          })
      );
    } else return;
  }, []);

  useEffect(() => {
    const fetchData = () => {
      if (session) {
        try {
          refreshTokens().then(() => setLoaded(true));
          const intervalId = setInterval(() => {
            refreshTokens();
          }, 20 * 60 * 1000);
          return () => clearInterval(intervalId);
        } catch (error) {
          console.error("Error refreshing tokens:", error);
        }
      }
    };
    fetchData();
  }, []);

  //For fetching user details like EA or PA
  const { isLoading: userListLoading } = useQuery(
    ["userDetails"],
    getUserDetails,
    {
      enabled: session !== null ? true : false,
      // staleTime: Infinity,
      onSuccess: (resp) => {
        setUserId(resp?.data?.groupId);
        setRoutes(getRoute(resp?.data?.role?.name));
        setUserData(resp);
      },
    }
  );

  //For fetching left nav bar details
  const { isLoading: menuListLoading } = useQuery(
    "getLeftMenuData",
    getSidebarMenu,
    {
      enabled: session !== null ? true : false,
      // staleTime: Infinity,
      onSuccess: (resp) => {
        setSideMenuList(resp?.data?.data);
      },
    }
  );

  const value = useMemo(() => {
    const logout = async () => {
      const url = getKrakendApiMap("logout");
      return await axios
        .post(url)
        .then((response) => {
          setSession(null);
          setAccessToken(null);
          setRefreshToken(null);
          setUserId(null);
          setUserMailId(null);
          setUserName(null);
          setUserData(null);
          setSideMenuList(null);
          window.localStorage.clear();
        })
        .catch((err) => {});
    };
    return {
      userId,
      setUserId,
      accessToken,
      setAccessToken,
      refreshToken,
      setRefreshToken,
      setSessionValue,
      session,
      userMailId,
      setUserMailId,
      userName,
      setUserName,
      logout,
      refreshTokens,
      sideMenuList,
      menuListLoading,
      userListLoading,
      userData,
      routes,
      openMobileSidebar,
      handleMobileSidebarOpen,
    };
  }, [
    userId,
    setUserId,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    setSessionValue,
    session,
    userMailId,
    setUserMailId,
    userName,
    setUserName,
    refreshTokens,
    sideMenuList,
    userData,
    routes,
    openMobileSidebar,
  ]);

  if (sideMenuList?.length === 0 && !menuListLoading && !userListLoading) {
    return <Page500 />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useMyContext = () => {};
