import React from "react";

const TextBase = ({ label, className, children }) => {
  return (
    <div
      className={`textBase ${className}`}
      style={{ fontSize: "clamp(14px, 2vw, 16px)" }}
    >
      {label}
    </div>
  );
};

export default TextBase;
