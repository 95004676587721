import React from "react";

const Page500 = () => {
  return (
    <div className="flex flex-col items-center mt-5">
      <h1
        className="font-semibold text-gray-700"
        style={{ fontSize: "clamp(50px, 5vw, 60px)" }}
      >
        500
      </h1>
      <p className="text-gray-700">Internal Server Error</p>
    </div>
  );
};

export default Page500;
