import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import * as Icons from "../../icons";
import { QliqleLogo } from "../../icons";
import SidebarSkltn from "../Skeloton/SidebarSkeloton";
import TextBase from "../ResponsiveText/TextBase";
import { AuthContext } from "../../context/AuthContext";

function Icon({ icon, ...props }) {
  const Icon = Icons[icon];
  if (Icon) return <Icon {...props} />;
}

const SidebarContent = () => {
  const { sideMenuList, menuListLoading } = useContext(AuthContext);

  return (
    <div className="overflow-x-hidden bg-white flex flex-col h-screen ">
      <div className="flex justify-center ">
        <QliqleLogo className="w-2/3 my-4 2xl:my-8 2xl:w-auto" />
      </div>
      {menuListLoading ? (
        <div className="w-[17rem] overflow-hidden h-full">
          <SidebarSkltn />
        </div>
      ) : (
        <ul className="flex flex-col overflow-y-auto h-full">
          {sideMenuList?.map((route, index) => (
            <div key={index}>
              <NavLink to={route?.path !== "" && route?.path}>
                {({ isActive }) => (
                  <li
                    className={`py-2 px-8  ${
                      route?.path !== "" ? "cursor-pointer" : "cursor-default"
                    } ${
                      route?.path !== "" && isActive
                        ? "border-r-4 border-black text-headerText font-semibold"
                        : "text-sidebarText"
                    }`}
                  >
                    <div className={`flex items-center  gap-3 text-sm`}>
                      <div className="">
                        <Icon
                          className="w-6 h-5"
                          aria-hidden="true"
                          icon={route?.icon ? route.icon : ""}
                        />
                      </div>
                      <span className="">{route?.name}</span>
                      {/* <TextBase label={route?.name} /> */}
                    </div>
                  </li>
                )}
              </NavLink>
              <ul className="mb-2">
                {route?.subRoutes
                  ? route?.subRoutes?.map((subRoute, i) => (
                      <NavLink
                        key={subRoute?.name}
                        to={subRoute?.path !== "" && subRoute?.path}
                      >
                        {({ isActive }) => (
                          <li
                            className={`pl-[70px] py-2 ${
                              subRoute?.path !== ""
                                ? "cursor-pointer"
                                : "cursor-default"
                            }  ${
                              subRoute?.path !== "" && isActive
                                ? "border-r-4 border-black text-headerText font-semibold"
                                : "text-sidebarText"
                            }`}
                          >
                            <div className={`flex items-center gap-3 text-sm `}>
                              <div className="">
                                <Icon
                                  className="w-6 h-5"
                                  aria-hidden="true"
                                  icon={subRoute?.icon ? subRoute?.icon : ""}
                                />
                              </div>
                              <span className="w-full max-w-[120px]">
                                {subRoute?.name}
                              </span>
                              {/* <TextBase label={subRoute?.name} /> */}
                            </div>
                          </li>
                        )}
                      </NavLink>
                    ))
                  : ""}
              </ul>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SidebarContent;
