import { Skeleton } from "@mui/material";
import React from "react";

const TableTitleSkltn = () => {
  return (
    <>
      <Skeleton width={70} />
      <Skeleton width={70} />
      <Skeleton width={20} />
    </>
  );
};

export default TableTitleSkltn;
